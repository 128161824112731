const gifData = [
    {
        subSystem: 'forward',
        systemTitle:'前雨刮系统',
        gifs:[
            {
                src: '/MBL_E-handbook_Design/3rd_layer/WS/Background/3rd_layer_WS_StandardDrive.jpg',
                'iPhone':{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:120,
                    fheight:120,
                },
                'Android':{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_StandardDrive_WSM3-FL.png',
                        modelName: '标准前雨刮驱动模块WSM3-FL',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'left'
                        },
                        Android:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_StandardDrive_WSD4S.png',
                        modelName: '标准前雨刮驱动模块WSD4S',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'right'
                        },
                        Android:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'right'
                        }
                    }
                ]
            },
            {
                src: '/MBL_E-handbook_Design/3rd_layer/WS/Background/3rd_layer_WS_ReversingDrive.jpg',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WCK.png',
                        modelName: '电子前雨刮驱动模块WCK',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WCM.png',
                        modelName: '电子前雨刮驱动模块WCM',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WSD4-FL.png',
                        modelName: '电子前雨刮驱动模块WSD4-FL',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WDD2.png',
                        modelName: '电子前雨刮驱动模块WDD2',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)',
                            float:'left'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WDD3.png',
                        modelName: '电子前雨刮驱动模块WDD3',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Reversing_WTK.png',
                        modelName: '电子前雨刮驱动模块WTK',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'calc(100%)'
                        }
                    }
                ]
            },
            {
                src:'/MBL_E-handbook_Design/3rd_layer/WS/Background/3rd_layer_WS_WiperArm&Blade.jpg',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Wiper_JWA.png',
                        modelName: '前雨刮刮杆和刮片JetWiperAdvanced-FL',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Wiper_JWB.png',
                        modelName: '前雨刮刮杆和刮片JetWiperBladeAero',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_Wiper_GWB2S4.png',
                        modelName: '前雨刮刮杆和刮片GWB2S4',
                        subSystem: 'forward',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'backward',
        systemTitle:'后雨刮系统',
        gifs:[
            {
                src: '/MBL_E-handbook_Design/3rd_layer/WS/Background/3rd_layer_WS_RearWiperSystem.jpg',
                iPhone:{
                    bottom:'calc(13%) !important',
                    top:'calc(63%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(5%) !important',
                    top:'calc(71%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_RearSystem_RWSH.png',
                        modelName: '后雨刮系统RWSH',
                        subSystem: 'backward',
                        iPhone:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'left'
                        },
                        Android:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/WS/3rd_layer_WS_RearSystem_ADO5.png',
                        modelName: '后雨刮系统ADO5',
                        subSystem: 'backward',
                        iPhone:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'right'
                        },
                        Android:{
                            width:'calc(43%) !important',
                            height:'auto',
                            float:'right'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'blow',
        systemTitle:'空调鼓风机',
        gifs:[
            {
                src: '/MBL_E-handbook_Design/3rd_layer/TS/Background/3rd_layer_TS_HVAC.jpg',
                iPhone:{
                    bottom:'calc(13%) !important',
                    top:'calc(65%) !important',
                    fwidth:100,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_HVAC_GBB.png',
                        modelName: '空调鼓风机GBB',
                        subSystem: 'blow',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'left'
                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_HVAC_GBM.png',
                        modelName: '空调鼓风机GBM',
                        subSystem: 'window',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'compress',
        systemTitle:'电动压缩机',
        gifs:[
            {
                src:'/MBL_E-handbook_Design/3rd_layer/TS/Background/3rd_layer_TS_eComp1.jpg',
                iPhone:{
                    bottom:'calc(13%) !important',
                    top:'calc(65%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_eComp_icon.png',
                        modelName: '电动压缩机800V',
                        subSystem: 'compress',
                        iPhone:{
                            width:'auto',
                            height:'100%'
                        },
                        Android:{
                            width:'auto',
                            height:'100%'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'cooldown',
        systemTitle:'电子冷却风扇',
        gifs:[
            {
                src:'/MBL_E-handbook_Design/3rd_layer/TS/Background/3rd_layer_TS_ECF.jpg',
                iPhone:{
                    bottom:'calc(13%) !important',
                    top:'calc(65%) !important',
                    fwidth:100,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_ECF_FED2.png',
                        modelName: '电子冷却风扇FED2',
                        subSystem: 'cooldown',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'left'

                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_ECF_ECM2.png',
                        modelName: '电子冷却风扇ECM2',
                        subSystem: 'cooldown',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'pump',
        systemTitle:'电子水泵',
        gifs:[
            {
                src:'/MBL_E-handbook_Design/3rd_layer/TS/Background/3rd_layer_TS_Pump.jpg',
                iPhone:{
                    bottom:'calc(13%) !important',
                    top:'calc(65%) !important',
                    fwidth:100,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_PUMP_PCE2.png',
                        modelName: '电子水泵PCE2',
                        subSystem: 'pump',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'left'
                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'left'
                        }
                    },
                    {
                        src: '/MBL_E-handbook_Design/3rd_layer/TS/3rd_layer_TS_PUMP_PAD2FL.png',
                        modelName: '电子水泵PCD2-FL',
                        subSystem: 'pump',
                        iPhone:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        },
                        Android:{
                            width:'45%',
                            height:'85%',
                            float:'right'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'wheel',
        systemTitle:'方向盘调节电机',
        gifs:[
            {
                src: '/static/gif/wheel/layout.gif',
                'iPhone':{
                    bottom:'calc(0%) !important',
                    top:'calc(77%) !important',
                    fwidth:120,
                    fheight:120,
                },
                'Android':{
                    bottom:'calc(5%) !important',
                    top:'calc(72%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/static/gif/wheel/AHC-D-budai.gif',
                        modelName: 'AHC-D-不带丝杠',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/wheel/AHC-D-dai.gif',
                        modelName: 'AHC-D-带丝杠',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/wheel/AHC-MS.gif',
                        modelName: 'AHC-MS',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/wheel/AHC-RS.gif',
                        modelName: 'AHC-RS',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            }
            ,
            {
                src: '/static/gif/wheel/SCA_long_trail.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(278px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(75%)',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/wheel/AHC-MS-l.gif',
                        modelName: 'AHC-MS长丝杠型',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'left'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'left'
                        }
                    },
                    {
                        src: '/static/gif/wheel/AHC-RS-l.gif',
                        modelName: 'AHC-RS长丝杠型',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'right'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'right'
                        }
                    }
                ]
            },
            {
                src:'/static/gif/wheel/SCA_decoulpling.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(73%) !important',
                    fwidth:'calc(125px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(35%) !important',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/wheel/AHC-D-dai.gif',
                        modelName: 'AHC-D-带丝杠-spindle',
                        subSystem: 'wheel',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'chair',
        systemTitle:'座椅电机',
        gifs:[
            {
                src: '/static/gif/chair/SD_0_garvity.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(79%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(5%) !important',
                    top:'calc(71%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/static/gif/chair/AHC-2.gif',
                        modelName: 'AHC-2',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/chair/AHC-P3.gif',
                        modelName: 'AHC-P3',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/chair/AHC-MS.gif',
                        modelName: 'AHC-MS',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/chair/AHC-RS.gif',
                        modelName: 'AHC-RS',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            },
            {
                src:'/static/gif/chair/SD_23_row.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(125px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(35%) !important',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/chair/AHC-2.gif',
                        modelName: 'AHC-2-row',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            },
            {
                src:'/static/gif/chair/B_SD_Height.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(125px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(35%) !important',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/chair/AHC-P3.gif',
                        modelName: 'AHC-P3',
                        subSystem: 'chair',
                        iPhone:{
                            width:'calc(100%)',
                            height:'100%',
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'100%',
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'window',
        systemTitle:'车窗电机',
        gifs:[
            {
                src: '/static/gif/window/WLD_PWM.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(278px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(75%)',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/window/FPG2-PWM.gif',
                        modelName: 'FPG2-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'left'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'left'
                        }
                    },
                    {
                        src: '/static/gif/window/FPS-PWM.gif',
                        modelName: 'FPS-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'right'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'calc(100%)',
                            float:'right'
                        }
                    }
                ]
            },
            {
                src: '/static/gif/window/WLD_micro.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(79%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(5%) !important',
                    top:'calc(71%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/static/gif/window/FPG2-PWM.gif',
                        modelName: 'FPG2-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-Hall.gif',
                        modelName: 'FPG2-Hall',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-SLP.gif',
                        modelName: 'FPG2-SLP',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            },
            {
                src:'/static/gif/window/WLD_Anti-pintch.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(79%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(5%) !important',
                    top:'calc(71%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/static/gif/window/FPC8.gif',
                        modelName: 'FPC8',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPS-PWM.gif',
                        modelName: 'FPS-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-PWM.gif',
                        modelName: 'FPG2-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-Hall.gif',
                        modelName: 'FPG2-Hall',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-SLP.gif',
                        modelName: 'FPG2-SLP',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            },
            {
                src:'/static/gif/window/WLD_open_close.gif',
                iPhone:{
                    bottom:'calc(5%) !important',
                    top:'calc(79%) !important',
                    fwidth:120,
                    fheight:120,
                },
                Android:{
                    bottom:'calc(5%) !important',
                    top:'calc(71%) !important',
                    fwidth:108,
                    fheight:108,
                },
                models:[
                    {
                        src: '/static/gif/window/FPG2-2pin+24V.gif',
                        modelName: 'FPG2-2pin+24V',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPC8.gif',
                        modelName: 'FPC8',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPS-PWM.gif',
                        modelName: 'FPS-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-PWM.gif',
                        modelName: 'FPG2-PWM',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-Hall.gif',
                        modelName: 'FPG2-Hall',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    },
                    {
                        src: '/static/gif/window/FPG2-SLP.gif',
                        modelName: 'FPG2-SLP',
                        subSystem: 'window',
                        iPhone:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        },
                        Android:{
                            width:'calc(100%) !important',
                            height:'calc(100%) !important'
                        }
                    }
                ]
            }
        ]
    },
    {
        subSystem: 'eca',
        systemTitle:'智能执行电机',
        gifs:[
            {
                src:'/static/gif/eca/PA_4wheel_drive.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(125px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(35%) !important',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/eca/ECA-M.gif',
                        modelName: 'ECA-M',
                        subSystem: 'eca',
                        iPhone:{
                            width:'calc(100%)',
                            height:'100%',
                        },
                        Android:{
                            width:'calc(100%)',
                            height:'100%',
                        }
                    }
                ]
            },
            {
                src:'/static/gif/eca/PA_2speed_parklock.gif',
                iPhone:{
                    bottom:'calc(0%) !important',
                    top:'calc(72%) !important',
                    fwidth:'calc(278px)',
                    fheight:'calc(125px)',
                },
                Android:{
                    bottom:'calc(8%) !important',
                    top:'calc(66%) !important',
                    fwidth:'calc(75%) !important',
                    fheight:'calc(108px)',
                },
                models:[
                    {
                        src: '/static/gif/eca/ECA-S.gif',
                        modelName: 'ECA-S',
                        subSystem: 'eca',
                        iPhone:{
                            width:'calc(45%)',
                            height:'100%',
                            float:'left'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'100%',
                            float:'left'
                        }
                    },
                    {
                        src: '/static/gif/eca/ECA-M.gif',
                        modelName: 'ECA-M',
                        subSystem: 'eca',
                        iPhone:{
                            width:'calc(45%)',
                            height:'100%',
                            float:'right'
                        },
                        Android:{
                            width:'calc(45%)',
                            height:'100%',
                            float:'right'
                        }
                    }
                ]
            }
        ]
    },
    

];
export default gifData;