import threeModelData from "./handbook.data";
import gifDatas from "./handbook.pic";

export const getThreeModelData = (p) => {
  const modelData = threeModelData.filter((data) => data.subSystem == p);
  if (modelData && modelData.length>0) {
    return modelData[0].models;
  }
  return [];
};

export const getGifData = (p) => {
  const gifData = gifDatas.filter((data) => data.subSystem == p);
  if (gifData && gifData.length>0) {
    return gifData[0].gifs;
  }
  return [];
};

export const getGifInfoData = (p) => {
  const gifData = gifDatas.filter((data) => data.subSystem == p);
  if (gifData && gifData.length>0) {
    return gifData[0];
  }
  return [];
};

export const getModelInfoData = (p) => {
  const modelData = threeModelData.filter((data) => data.subSystem == p);
  if (modelData) {
    return modelData[0];
  }
  return [];
};

export const getThreeModel = (p,name) => {
  const modelData = threeModelData.filter((data) => data.subSystem == p);
  if (modelData && modelData.length>0) {
    const model = modelData[0].models.filter((data) => data.name == name);
    return model;
  }
  return [];
};