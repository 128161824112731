const threeModelData = [
    {
      subSystem:'forward',
      systemTitle:'前雨刮系统',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/3.wiper_system_wsm3_fl_motor_var3_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WSM3-FL.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_WSM3-FL-01.png",
          name:"标准前雨刮驱动模块WSM3-FL",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/4.wiper_motor_wsd4s_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WSD4S.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_WSD4S-01.png",
          name:"标准前雨刮驱动模块WSD4S",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/7.wiper_compact_kit_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WCK.jpg",
          name:"电子前雨刮驱动模块WCK",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/6.wiper_compact_module_with_ec_drive_wdd3_var1_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WCM.jpg",
          name:"电子前雨刮驱动模块WCM",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/4.wiper_motor_wsd4s_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WSD4-FL.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_WSD4-FL-01.png",
          name:"电子前雨刮驱动模块WSD4-FL",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/WTK_Combined-01.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WTK.jpg",
          name:"电子前雨刮驱动模块WTK",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/10.wdd2_var2_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WDD2.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_WDD2-01.png",
          name:"电子前雨刮驱动模块WDD2",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/11.wiper_ec_motor_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_WDD3.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_WDD3-01.png",
          name:"电子前雨刮驱动模块WDD3",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/13.jet_wiper_advanced_fl_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_Jet_Wiper_Advanced-FL.jpg",
          name:"前雨刮刮杆和刮片JetWiperAdvanced-FL",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/14.jet_wiper_blade_aero_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_Jet_Wiper_Jet_Wiper_Blade_Aero.jpg",
          name:"前雨刮刮杆和刮片JetWiperBladeAero",
        },{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/18.gwb2s4_with_aero_clip_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_GWB2S4.jpg",
          name:"前雨刮刮杆和刮片GWB2S4",
        }
      ]
    },
    {
      subSystem:'backward',
      systemTitle:'后雨刮系统',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/21.RWSH.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_RWSH.jpg",
          name:"后雨刮系统RWSH",
        },
        {
          modelPath:"/MBL_E-handbook_Design/4th_layer/WS/E handbook WS Pictures/20.ADO5.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/WS/4th_layer_WS_ADO5.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/WS/5th_layer_WS_ADO5-01.png",
          name:"后雨刮系统ADO5",
        }
      ]
    },
    {
      subSystem:'blow',
      systemTitle:'空调鼓风机',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2020_02_27_global_brushless_blower_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_GBB.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_GBB.png",
          name:"空调鼓风机GBB",
        },
        {
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2016_08_30_gbm_motor_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_GBM.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_GBM.png",
          name:"空调鼓风机GBM",
        }
      ]
    },
    {
      subSystem:'compress',
      systemTitle:'电动压缩机',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2022_12_08_ecompressor_800v_new_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_eComp.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_eComp.png",
          name:"电动压缩机800V",
        }
      ]
    },
    {
      subSystem:'cooldown',
      systemTitle:'电子冷却风扇',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2021_07_06_engine_cooling_fan_fed20_xl_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_FED2.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_FED2.png",
          name:"电子冷却风扇FED2",
        },
        {
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2020_11_17_ecm20_hr(1).png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_ECM2.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_ECM2.png",
          name:"电子冷却风扇ECM2",
        }
      ]
    },
    {
      subSystem:'pump',
      systemTitle:'电子水泵',
      models:[{
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2022_06_27_pce20_new_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_PCE2.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_PCE2.png",
          name:"电子水泵PCE2",
        },
        {
          modelPath:"/MBL_E-handbook_Design/4th_layer/TS/E handbook TS Pictures/2017_03_17_pad2_fl_var1_hr.png",
          imgsrc:"/MBL_E-handbook_Design/4th_layer/TS/4th_layer_TS_PAD2.FL.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/TS/5th_layer_TS_PAD2FL.png",
          name:"电子水泵PCD2-FL",
        }
      ]
    },
    {
      subSystem:'chair',
      systemTitle:'座椅电机',
      description:'secondLevelcomfortActuators secondLevelcomfortActuators comfortActuators comfortActuators',
      background:'',
      models:[{
          modelPath:"/static/models/PA/CHAIR/AHC-P3.obj",
          modelMaterial:"/static/models/PA/CHAIR/AHC-P3.mtl",
          imgsrc:"/static/models/PA/CHAIR/C_AHC-P3.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_座椅AHCP3-01.png",
          modelProperty:{
          },
          name:"AHC-P3",
          scale:[0.4,0.4,0.4],
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/CHAIR/AHC-2.obj",
          modelMaterial:"/static/models/PA/CHAIR/AHC-2.mtl",
          imgsrc:"/static/models/PA/CHAIR/C_AHC-2.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_座椅AHC2-01.png",
          modelProperty:{
          },
          name:"AHC-2",
          rotate:[0,0,40]
        },{
          modelPath:"/static/models/PA/CHAIR/AHC-2.obj",
          modelMaterial:"/static/models/PA/CHAIR/AHC-2.mtl",
          imgsrc:"/static/models/PA/CHAIR/C_AHC-2_back_row.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_座椅AHC2-01.png",
          modelProperty:{
          },
          name:"AHC-2-row",
          rotate:[0,0,40]
        },{
          modelPath:"/static/models/PA/WHEEL/AHC-MS.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-MS.mtl",
          imgsrc:"/static/models/PA/CHAIR/C_AHC_MS.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHCMS-01.png",
          modelProperty:{
          },
          name:"AHC-MS",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-RS.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-RS.mtl",
          imgsrc:"/static/models/PA/CHAIR/C_AHC_RS.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHCRS-01.png",
          modelProperty:{
          },
          name:"AHC-RS",
          rotate:[-10,-10,-15]
        }]
    
    },
    {
      subSystem:'wheel',
      systemTitle:'方向盘调节电机',
      description:'secondLevelcomfortActuators secondLevelcomfortActuators comfortActuators comfortActuators',
      background:'',
      models:[
        {
          modelPath:"/static/models/PA/WHEEL/AHC-D-budai.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-D-budai.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC-D-budai.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHC-D不带丝杠-01.png",
          modelProperty:{
          },
          name:"AHC-D-不带丝杠",
          rotate:[0,0,40]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-D-dai.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-D-dai.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC-D-dai.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHC-D带丝杠-01.png",
          modelProperty:{
          },
          name:"AHC-D-带丝杠",
          rotate:[0,0,40]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-D-dai.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-D-dai.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC-D_w_spindle.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHC-D带丝杠-01.png",
          modelProperty:{
          },
          name:"AHC-D-带丝杠-spindle",
          rotate:[0,0,40]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-MS-l.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-MS-l.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC-MS_MS_long.jpg",
          modelProperty:{
          },
          name:"AHC-MS长丝杠型",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-RS-l.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-RS-l.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC-MS_RS_long.jpg",
          modelProperty:{
          },
          name:"AHC-RS长丝杠型",
          rotate:[-10,-10,-15]
        }
        ,
        {
          modelPath:"/static/models/PA/WHEEL/AHC-MS.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-MS.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC_MS.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHCMS-01.png",
          modelProperty:{
          },
          name:"AHC-MS",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WHEEL/AHC-RS.obj",
          modelMaterial:"/static/models/PA/WHEEL/AHC-RS.mtl",
          imgsrc:"/static/models/PA/WHEEL/C_AHC_RS.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_方向盘AHCRS-01.png",
          modelProperty:{
          },
          name:"AHC-RS",
          rotate:[-10,-10,-15]
        }]
    
    },
    {
      subSystem:'window',
      systemTitle:'举窗电机',
      description:'secondLevelcomfortActuators secondLevelcomfortActuators comfortActuators comfortActuators',
      background:'',
      models:[
        {
          modelPath:"/static/models/PA/WINDOW/FPG2_7pin_PWM.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPG2_7pin_PWM.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPG2_PWM.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_车窗FPG2PWM-01.png",
          modelProperty:{
          },
          name:"FPG2-PWM",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WINDOW/FPS_7pin_PWM.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPS_7pin_PWM.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPS_PWM.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_车窗FPSPWM-01.png",
          modelProperty:{
          },
          name:"FPS-PWM",
          rotate:[0,0,40]
        },
        {
          modelPath:"/static/models/PA/WINDOW/FPG2 SLP.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPG2_SLP.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPG2_SLP.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_车窗FPG2SLP-01.png",
          modelProperty:{
          },
          name:"FPG2-SLP",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WINDOW/FPG2_Hall.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPG2_Hall.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPG2_HALL.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_车窗FPG2hall-01.png",
          modelProperty:{
          },
          name:"FPG2-Hall",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/WINDOW/FPC8.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPC8.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPC8.jpg",
          modelProperty:{
          },
          name:"FPC8",
          rotate:[15,15,10]
        },
        {
          modelPath:"/static/models/PA/WINDOW/ECA-S.obj",
          modelMaterial:"/static/models/PA/WINDOW/ECA-S.mtl",
          imgsrc:"",
          modelProperty:{
          },
          name:"ECA-S",
          rotate:[-10,-10,-15]
        },{
          modelPath:"/static/models/PA/WINDOW/FPG2 2pin+24V.obj",
          modelMaterial:"/static/models/PA/WINDOW/FPG2_2pin+24V.mtl",
          imgsrc:"/static/models/PA/WINDOW/C_FPG2_2PIN_24V.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/CA/5th_layer_CA_车窗FPG22PIN24V-01.png",
          modelProperty:{
          },
          name:"FPG2-2pin+24V",
          rotate:[-10,-10,-15]
        }]
    
    },
    {
      subSystem:'eca',
      systemTitle:'智能调节电机',
      description:'secondLevelcomfortActuators secondLevelcomfortActuators comfortActuators comfortActuators',
      background:'',
      models:[
        {
          modelPath:"/static/models/PA/ECA/ECA-S.obj",
          modelMaterial:"/static/models/PA/ECA/ECA-S.mtl",
          imgsrc:"/static/models/PA/ECA/C_ECA-S.jpg",
          modelProperty:{
          },
          name:"ECA-S",
          rotate:[-10,-10,-15]
        },
        {
          modelPath:"/static/models/PA/ECA/ECA-M.obj",
          modelMaterial:"/static/models/PA/ECA/ECA-M.mtl",
          imgsrc:"/static/models/PA/ECA/C_ECA-M.jpg",
          infoPath:"/MBL_E-handbook_Design/5th_layer/PA/5th_layer_PA_ECA-M-01.png",
          modelProperty:{
          },
          name:"ECA-M",
          rotate:[-15,-10,15],
          scale:[0.6,0.6,0.6]
        }]
    }
];
export default threeModelData;